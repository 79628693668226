<template>
    <div>
        <div class="mp2-bg pb-5">
            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-12 text-center pt-5">
                        <h2>{{ $t('message.company.title') }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pt-5 pb-5 company">
            <div class="row pb-5">
                <div class="col-12 col-md-6 pb-5">
                    <img :src="require(`@/assets/images/OMG_K2GO-1199x629.png`)" class="img-fluid" alt="">
                </div>
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h3>{{ $t('message.company.title2') }}</h3>
                        </div>
                        <div class="col-12 pb-2">
                            <p><strong>{{ $t('message.company.text1') }}</strong></p>
                        </div>

                        <div class="col-12 pb-2">
                            <p>{{ $t('message.company.text2') }}</p>
                        </div>

                        <div class="col-12 italic">
                            <p>- Diogo, CEO</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 pb-5">
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h3>{{ $t('message.company.text3') }}</h3>
                        </div>
                        <div class="col-12 pb-3">
                            <p>{{ $t('message.company.text4') }}</p>
                        </div>
                        <div class="col-12 pb-3">
                            <ul class="list">
                                <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list1') }}</li>
                                <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list2') }}</li>
                                <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list3') }}</li>
                                <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list4') }}</li>
                                <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list5') }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 pb-5">
                    <img :src="require(`@/assets/images/detalhe_k2go-798x449.jpg`)" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <div class="mp-dark-bg">
            <div class="container">
                <WhyZone></WhyZone>
            </div>
        </div>


        <div class="mp-location-bg pt-5 pb-5">
            <Location></Location>
        </div>
    </div>
</template>

<script>

    import json     from '../../website-data.json';
    import Location from '../components/Location';
    import WhyZone  from '../components/WhyZone';

    export default {
        name       : 'CompanyPage',
        components : {
            WhyZone,
            Location
        },
        data       : function () {
            return {
                json : json,
                lang : this.$route.params.lang
            };
        },
        methods    : {}
    };
</script>
